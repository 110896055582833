import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AuthUsecase, DistinctSubject, recursiveQuery } from '@daikin-tic/dxone-com-lib';

import {
  ThinkletCreateParams,
  ThinkletFeatureUpdateParams,
  ThinkletReactivateParams,
  ThinkletRetryParams,
  ThinkletUpdateParams,
  Thinklets,
} from '../models/thinklet.model';
import { ThinkletGateway } from '../usecases/thinklet.gateway';
import { ThinkletUsecase } from '../usecases/thinklet.usecase';

@Injectable()
export class ThinkletInteractor extends ThinkletUsecase {
  get thinklets$(): Observable<Thinklets> {
    return this._thinklets;
  }

  private readonly _thinklets = new DistinctSubject<Thinklets>(new Thinklets());

  constructor(
    private _authUsecase: AuthUsecase,
    private _thinkletGateway: ThinkletGateway,
  ) {
    super();

    this._authUsecase.authState$
      .pipe(
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      )
      .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
  }

  reload(): void {
    this.onSignIn();
  }

  createThinklets(params: ThinkletCreateParams): Observable<string> {
    const result = new AsyncSubject<string>();
    this._thinkletGateway.createThinklets(params).subscribe({
      next: ({ items, registerId }) => {
        items.forEach(thinklet => {
          thinklet.commands$ = recursiveQuery(queryParams => this._thinkletGateway.listThinkletCommands(thinklet.imei, queryParams), {});
          this._thinklets.next(this._thinklets.value.set(thinklet));
        });
        result.next(registerId);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateThinklets(params: ThinkletUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.updateThinklets(params).subscribe({
      next: ({ items }) => {
        const thinklets = items.reduce((acc, cur) => {
          cur.commands$ = recursiveQuery(queryParams => this._thinkletGateway.listThinkletCommands(cur.imei, queryParams), {});
          return acc.set(cur);
        }, this._thinklets.value);
        this._thinklets.next(thinklets);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  retryThinklets(params: ThinkletRetryParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.retryThinklets(params).subscribe({
      next: ({ items }) =>
        items.forEach(thinklet => {
          thinklet.commands$ = recursiveQuery(queryParams => this._thinkletGateway.listThinkletCommands(thinklet.imei, queryParams), {});
          this._thinklets.next(this._thinklets.value.set(thinklet));
        }),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteThinklet(imei: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.deleteThinklet(imei).subscribe({
      next: () => this._thinklets.next(this._thinklets.value.delete(imei)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deactivateThinklet(imei: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.deactivateThinklet(imei).subscribe({
      next: thinklet => {
        thinklet.commands$ = recursiveQuery(queryParams => this._thinkletGateway.listThinkletCommands(thinklet.imei, queryParams), {});
        this._thinklets.next(this._thinklets.value.set(thinklet));
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  reactivateThinklet(imei: string, params: ThinkletReactivateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.reactivateThinklet(imei, params).subscribe({
      next: thinklet => {
        thinklet.commands$ = recursiveQuery(queryParams => this._thinkletGateway.listThinkletCommands(thinklet.imei, queryParams), {});
        this._thinklets.next(this._thinklets.value.set(thinklet));
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateThinkletFeature(imei: string, params: ThinkletFeatureUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.updateThinkletFeature(imei, params).subscribe({
      next: thinklet => {
        thinklet.commands$ = recursiveQuery(queryParams => this._thinkletGateway.listThinkletCommands(thinklet.imei, queryParams), {});
        this._thinklets.next(this._thinklets.value.set(thinklet));
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._thinkletGateway.listThinklets(params), {}).subscribe(thinklets => {
      thinklets.forEach(thinklet => {
        thinklet.commands$ = recursiveQuery(params => this._thinkletGateway.listThinkletCommands(thinklet.imei, params), {});
      });
      this._thinklets.next(new Thinklets(thinklets));
    });
  }

  private onSignOut(): void {
    this._thinklets.next(new Thinklets());
  }
}
