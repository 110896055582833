import { Observable } from 'rxjs';

import {
  ThinkletCreateParams,
  ThinkletFeatureUpdateParams,
  ThinkletReactivateParams,
  ThinkletRetryParams,
  ThinkletUpdateParams,
  Thinklets,
} from '../models/thinklet.model';

export abstract class ThinkletUsecase {
  abstract get thinklets$(): Observable<Thinklets>;

  abstract reload(): void;
  abstract createThinklets(params: ThinkletCreateParams): Observable<string>;
  abstract updateThinklets(params: ThinkletUpdateParams): Observable<never>;
  abstract retryThinklets(params: ThinkletRetryParams): Observable<never>;
  abstract deleteThinklet(imei: string): Observable<never>;
  abstract deactivateThinklet(imei: string): Observable<never>;
  abstract reactivateThinklet(imei: string, params: ThinkletReactivateParams): Observable<never>;
  abstract updateThinkletFeature(imei: string, params: ThinkletFeatureUpdateParams): Observable<never>;
}
