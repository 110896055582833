import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import {
  Thinklet,
  ThinkletCommand,
  ThinkletCommandQueryParams,
  ThinkletCreateParams,
  ThinkletFeatureUpdateParams,
  ThinkletQueryParams,
  ThinkletReactivateParams,
  ThinkletRetryParams,
  ThinkletUpdateParams,
} from '../models/thinklet.model';
import { ThinkletGateway } from '../usecases/thinklet.gateway';

@Injectable()
export class ThinkletService extends ThinkletGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listThinklets(params: ThinkletQueryParams): Observable<ListResponse<Thinklet>> {
    return this._http.get<ListResponse<Thinklet>>('api/rss/thinklets', { params });
  }

  createThinklets(params: ThinkletCreateParams): Observable<ListResponse<Thinklet> & { registerId: string }> {
    return this._http.post<ListResponse<Thinklet> & { registerId: string }>('api/rss/thinklets', params);
  }

  updateThinklets(params: ThinkletUpdateParams): Observable<ListResponse<Thinklet>> {
    return this._http.put<ListResponse<Thinklet>>('api/rss/thinklets', params);
  }

  retryThinklets(params: ThinkletRetryParams): Observable<ListResponse<Thinklet>> {
    return this._http.post<ListResponse<Thinklet>>('api/rss/thinklets/retries', params);
  }

  deleteThinklet(imei: string): Observable<void> {
    return this._http.delete<void>(`api/rss/thinklets/${imei}`);
  }

  deactivateThinklet(imei: string): Observable<Thinklet> {
    return this._http.post<Thinklet>(`api/rss/thinklets/${imei}/deactivate`, null);
  }

  reactivateThinklet(imei: string, params: ThinkletReactivateParams): Observable<Thinklet> {
    return this._http.post<Thinklet>(`api/rss/thinklets/${imei}/reactivate`, params);
  }

  listThinkletCommands(imei: string, params: ThinkletCommandQueryParams): Observable<ListResponse<ThinkletCommand>> {
    return this._http.get<ListResponse<ThinkletCommand>>(`api/rss/thinklets/${imei}/commands`, { params });
  }

  updateThinkletFeature(imei: string, params: ThinkletFeatureUpdateParams): Observable<Thinklet> {
    return this._http.put<Thinklet>(`api/rss/thinklets/${imei}/feature`, params);
  }
}
