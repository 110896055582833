import { Observable } from 'rxjs';

import { ListResponse } from '@daikin-tic/dxone-com-lib';

import {
  Thinklet,
  ThinkletCommand,
  ThinkletCommandQueryParams,
  ThinkletCreateParams,
  ThinkletFeatureUpdateParams,
  ThinkletQueryParams,
  ThinkletReactivateParams,
  ThinkletRetryParams,
  ThinkletUpdateParams,
} from '../models/thinklet.model';

export abstract class ThinkletGateway {
  abstract listThinklets(params: ThinkletQueryParams): Observable<ListResponse<Thinklet>>;
  abstract createThinklets(params: ThinkletCreateParams): Observable<ListResponse<Thinklet> & { registerId: string }>;
  abstract updateThinklets(params: ThinkletUpdateParams): Observable<ListResponse<Thinklet>>;
  abstract retryThinklets(params: ThinkletRetryParams): Observable<ListResponse<Thinklet>>;
  abstract deleteThinklet(imei: string): Observable<void>;
  abstract deactivateThinklet(imei: string): Observable<Thinklet>;
  abstract reactivateThinklet(imei: string, params: ThinkletReactivateParams): Observable<Thinklet>;
  abstract listThinkletCommands(imei: string, params: ThinkletCommandQueryParams): Observable<ListResponse<ThinkletCommand>>;
  abstract updateThinkletFeature(imei: string, params: ThinkletFeatureUpdateParams): Observable<Thinklet>;
}
